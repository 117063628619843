"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function loadScript(url) {
    const script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
}
function getNamespaceMembers(module, namespace) {
    const ns = {};
    const dotPrefix = namespace + ".";
    const dollarPrefix = namespace + "$";
    const nameOffset = namespace.length + 1;
    const fullNameArray = Object.keys(module).filter(x => x.startsWith(dotPrefix) || x.startsWith(dollarPrefix));
    for (const fullName of fullNameArray) {
        const name = fullName.substr(nameOffset);
        ns[name] = module[fullName];
    }
    return ns;
}
class ModuleLoader {
    static loadAsync(path) {
        const rootPath = path.endsWith("/") ? path : path + "/";
        return new Promise((resolve, reject) => {
            window.Module = {
                locateFile: url => rootPath + url,
                postRun: [
                    () => {
                        // Emscripten generates flat names for exports with namespaces.
                        // We'll build an object tree from those flat names and
                        // merge into the global object.
                        window.eebzlabs = getNamespaceMembers(Module, "eebzlabs");
                        resolve();
                    }
                ]
            };
            loadScript(rootPath + "eebzlabs-module.js");
        });
    }
}
exports.ModuleLoader = ModuleLoader;
