"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("../../lib/index");
let surfaceInteraction;
function setSurfaceAsync(surfaceName) {
    return __awaiter(this, void 0, void 0, function* () {
        yield surfaceInteraction.setSurfaceAsync(`/assets/surfaces/${surfaceName}/rings`);
    });
}
function initUI() {
    document.getElementById("surface-selector").addEventListener("change", e => {
        const surfaceName = e.target.value;
        setSurfaceAsync(surfaceName);
    });
    document
        .getElementById("wireframe-checkbox")
        .addEventListener("change", e => {
        const wireframeMode = e.target.checked;
        surfaceInteraction.state.wireframeMode = wireframeMode;
    });
    document.getElementById("position-input").addEventListener("change", e => {
        const position = +e.target.value;
        surfaceInteraction.state.position = position;
    });
}
function main() {
    return __awaiter(this, void 0, void 0, function* () {
        // Init WASM module
        yield index_1.ModuleLoader.loadAsync("assets/js");
        // Bind SurfaceInteraction to existing canvas
        const viewport = document.getElementById("viewport");
        surfaceInteraction = new index_1.SurfaceInteraction(viewport);
        yield setSurfaceAsync("surface1");
        initUI();
    });
}
main();
