"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const three_orbitcontrols_ts_1 = require("three-orbitcontrols-ts");
const renderer_1 = require("./renderer");
const surface_actor_1 = require("./surface-actor");
const surface_loader_1 = require("./surface-loader");
class SurfaceInteraction {
    constructor(canvas) {
        this.updateFrame = this.updateFrame.bind(this);
        this.state = {
            wireframeMode: false,
            position: 0
        };
        this.renderer = new renderer_1.Renderer(canvas, this.updateFrame);
        const controls = new three_orbitcontrols_ts_1.OrbitControls(this.renderer.camera, this.renderer.domElement);
        this.renderer.camera.position.set(8000, 0, 0);
        controls.update();
        this.heatmapMaterial = new THREE.MeshBasicMaterial({
            vertexColors: THREE.VertexColors,
            side: THREE.DoubleSide
        });
        this.wireframeMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            wireframe: true
        });
    }
    updateFrame(deltaTime) {
        if (this.surfaceT && this.surfaceZ) {
            const mat = this.state.wireframeMode
                ? this.wireframeMaterial
                : this.heatmapMaterial;
            this.surfaceT.mesh.material = mat;
            this.surfaceZ.mesh.material = mat;
            this.surfaceZ.mesh.position.z = this.state.position;
        }
    }
    setSurfaceAsync(surfacePath) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.surfaceT) {
                this.renderer.scene.remove(this.surfaceT.mesh);
                this.surfaceT = undefined;
            }
            if (this.surfaceZ) {
                this.renderer.scene.remove(this.surfaceZ.mesh);
                this.surfaceZ = undefined;
            }
            const surface = yield surface_loader_1.SurfaceLoader.loadAsync(surfacePath);
            this.surfaceT = new surface_actor_1.SurfaceActor(new THREE.Vector3(0, 0, 0), surface.generateGeometry(0 /* T */, 1000), this.heatmapMaterial);
            this.renderer.scene.add(this.surfaceT.mesh);
            this.surfaceZ = new surface_actor_1.SurfaceActor(new THREE.Vector3(0, 0, this.state.position), surface.generateGeometry(1 /* Z */, 1000), this.heatmapMaterial);
            this.renderer.scene.add(this.surfaceZ.mesh);
        });
    }
}
exports.SurfaceInteraction = SurfaceInteraction;
