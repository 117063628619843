"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class Renderer {
    constructor(canvas, updateCallback) {
        this.canvas = canvas;
        this.updateCallback = updateCallback;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, this.canvas.clientWidth / this.canvas.clientHeight, 1, 20000);
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        });
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
        requestAnimationFrame(this.render.bind(this));
        window.addEventListener("resize", this.handleWindowResize.bind(this));
        this.handleWindowResize();
    }
    get domElement() {
        return this.renderer.domElement;
    }
    render(deltaTime) {
        requestAnimationFrame(this.render.bind(this));
        this.updateCallback(deltaTime);
        this.renderer.render(this.scene, this.camera);
    }
    handleWindowResize() {
        this.canvas.style.width = "100%";
        this.canvas.style.height = "100%";
        this.camera.aspect =
            this.canvas.clientHeight > 0
                ? this.canvas.clientWidth / this.canvas.clientHeight
                : 0;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    }
}
exports.Renderer = Renderer;
