"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class SurfaceActor {
    constructor(origin, geometry, material) {
        this.geometry = new THREE.BufferGeometry();
        this.geometry.addAttribute("position", new THREE.BufferAttribute(geometry.positions, 3));
        this.geometry.addAttribute("color", new THREE.BufferAttribute(geometry.colors, 3, true));
        this.geometry.setIndex(new THREE.BufferAttribute(geometry.indices, 1));
        this.mesh = new THREE.Mesh(this.geometry, material);
        this.mesh.position.copy(origin);
    }
    get isWireframe() {
        return this.mesh.material.wireframe;
    }
}
exports.SurfaceActor = SurfaceActor;
