"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class SurfaceLoader {
    static loadFileAsync(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(url);
            const arrayBuffer = yield response.arrayBuffer();
            const array = new Uint8Array(arrayBuffer);
            const heap = new eebzlabs.HeapMemory(array.length);
            heap.data.set(array);
            return heap;
        });
    }
    static loadAsync(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const promiseX = SurfaceLoader.loadFileAsync(url + ".X");
            const promiseT = SurfaceLoader.loadFileAsync(url + ".T");
            const promiseZ = SurfaceLoader.loadFileAsync(url + ".Z");
            const [dataX, dataT, dataZ] = yield Promise.all([
                promiseX,
                promiseT,
                promiseZ
            ]);
            return eebzlabs.Surface.load(dataX, dataT, dataZ);
        });
    }
}
exports.SurfaceLoader = SurfaceLoader;
